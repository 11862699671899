<template>
  <div>
    <v-carousel
      height="calc(100vh - 64px)"
      :hide-delimiters="true"
      v-if="$vuetify.breakpoint.name !== 'xs'"
    >
      <v-carousel-item
        src="@/assets/web/marmol.jpg"
      >
        <v-sheet height="calc(100vh - 64px)" color="transparent">
          <v-row class="fill-height" align="center" justify="center">
            <v-img src="@/assets/logo_portada_crc.png" max-width="40vw" width="50vw"></v-img>
          </v-row>
        </v-sheet>
      </v-carousel-item>

      <v-carousel-item
        v-for="(item, i) in items[lang]"
        :key="i"
        :src="item.src"
        contain
      >
      </v-carousel-item>

    </v-carousel>

    <v-row v-else >
      
      <v-col cols="12">
        <v-img src="@/assets/movil/marmol.jpg">
          <v-sheet height="100%" color="transparent">
            <v-row class="fill-height" align="center" justify="center">
              <v-img src="@/assets/logo_portada_crc.png" max-width="80vw"></v-img>
            </v-row>
          </v-sheet>
        </v-img>
      </v-col>

      <v-col cols="12">
        <v-img
          v-for="(item, i) in itemsMovil[lang]"
          :key="i"
          :src="item.src"
          contain
        >
          <!-- <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="black"
                :width="7"
                :size="70"
              ></v-progress-circular>
            </v-row>
          </template> -->
        </v-img>
      </v-col>

    </v-row>
  </div>
</template>

<script>
//web
import img1 from '@/assets/web/crc/crc-01.jpg';
import img2 from '@/assets/web/crc/crc-02.jpg';
import img3 from '@/assets/web/crc/crc-03.jpg';
import img4 from '@/assets/web/crc/crc-04.jpg';
import img5 from '@/assets/web/crc/crc-05.jpg';
import img6 from '@/assets/web/crc/crc-06.jpg';
import img7 from '@/assets/web/crc/crc-07.jpg';
import img8 from '@/assets/web/crc/crc-08.jpg';

import en_img1 from '@/assets/web/crc/en/crc-01.jpg';
import en_img2 from '@/assets/web/crc/en/crc-02.jpg';
import en_img3 from '@/assets/web/crc/en/crc-03.jpg';
import en_img4 from '@/assets/web/crc/en/crc-04.jpg';
import en_img5 from '@/assets/web/crc/en/crc-05.jpg';
import en_img6 from '@/assets/web/crc/en/crc-06.jpg';
import en_img7 from '@/assets/web/crc/en/crc-07.jpg';
import en_img8 from '@/assets/web/crc/en/crc-08.jpg';
//movil
import img1_movil from '@/assets/movil/crc/responsive crc-01.jpg';
import img2_movil from '@/assets/movil/crc/responsive crc-02.jpg';
import img3_movil from '@/assets/movil/crc/responsive crc-03.jpg';
import img4_movil from '@/assets/movil/crc/responsive crc-04.jpg';
import img5_movil from '@/assets/movil/crc/responsive crc-05.jpg';
import img6_movil from '@/assets/movil/crc/responsive crc-06.jpg';
import img7_movil from '@/assets/movil/crc/responsive crc-07.jpg';

import en_img1_movil from '@/assets/movil/crc/en/responsive crc-01.jpg';
import en_img2_movil from '@/assets/movil/crc/en/responsive crc-02.jpg';
import en_img3_movil from '@/assets/movil/crc/en/responsive crc-03.jpg';
import en_img4_movil from '@/assets/movil/crc/en/responsive crc-04.jpg';
import en_img5_movil from '@/assets/movil/crc/en/responsive crc-05.jpg';
import en_img6_movil from '@/assets/movil/crc/en/responsive crc-06.jpg';
import en_img7_movil from '@/assets/movil/crc/en/responsive crc-07.jpg';

export default {
  name: 'Home',
  data() {
    return {
      items: {
        es: [
          { 'src': img1, },
          { 'src': img2, },
          { 'src': img3, },
          { 'src': img4, },
          { 'src': img5, },
          { 'src': img6, },
          { 'src': img7, },
          { 'src': img8, },
        ],
        en: [
          { 'src': en_img1, },
          { 'src': en_img2, },
          { 'src': en_img3, },
          { 'src': en_img4, },
          { 'src': en_img5, },
          { 'src': en_img6, },
          { 'src': en_img7, },
          { 'src': en_img8, },
        ],

      },
      itemsMovil: {
        es: [
          {'src': img1_movil},
          {'src': img2_movil},
          {'src': img3_movil},
          {'src': img4_movil},
          {'src': img5_movil},
          {'src': img6_movil},
          {'src': img7_movil},
        ],
        en: [
          {'src': en_img1_movil},
          {'src': en_img2_movil},
          {'src': en_img3_movil},
          {'src': en_img4_movil},
          {'src': en_img5_movil},
          {'src': en_img6_movil},
          {'src': en_img7_movil},
        ]
      }
    }
  },
  computed: {
    lang() {
      return this.$route.params.lang
    },
  }
}
</script>
